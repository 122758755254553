
import styled from 'styled-components'
import useTranslation from 'next-translate/useTranslation'

import device from 'utils/device'
import TextMock from 'components/TextMock'
import Reveal from 'components/Reveal'
import Button from 'components/Button'

export default function Approach({scrollButtons}) {
    const { t } = useTranslation('home')

    const items = t('home_approach_blocks', {}, { returnObjects: true })

    const handleClick = () => {
        const plansElement = document.getElementById("plans")
        if (plansElement) {
            plansElement.scrollIntoView({ behavior: "smooth" })
        }
    }

    return (
        <Container>
            <Reveal>
                <h2 className="h2">{t('home_approach_name')}</h2>
            </Reveal>
            <Content>
                <Bg/>
                <Wrap>
                    {items.map((item, index) => (
                        <TextMock key={index} data={item} invert={index % 2 == 1}>
                            {scrollButtons && index % 2 == 1 && <Button onClick={handleClick}>Get my plan</Button>}
                        </TextMock>
                    ))}
                </Wrap>
            </Content>
        </Container>
    )
}

const Container = styled.div`
    h2 {
        margin-bottom: 0.8rem;
        text-align: center;
        max-width: 5rem;
        margin-left: auto;
        margin-right: auto;
        ${device.mobile} {
            margin-bottom: 0.36rem;
        }
    }
`

const Content = styled.div`
    position: relative;
`

const Bg = styled.div`
    background: #38869E;
    position: absolute;
    left: 0;
    top: 0.8rem;
    bottom: 0.8rem;
    width: 100%;
    ${device.mobile} {
        bottom: 0;
    }
`

const Wrap = styled.div`
    width: 7.94rem;
    margin: 0 auto;
    position: relative;
    ${device.mobile} {
        width: auto;
    }
`


