import styled from 'styled-components'

import device from 'utils/device'
import Mockup from 'components/Mockup4'
import Reveal from 'components/Reveal'

export default function TextMock({data, invert, children}) {
    return (
        <Container invert={invert}>
            <Mockup image={data.image} />
            <Content>
                <Reveal>
                    <h3 className="h2" dangerouslySetInnerHTML={{__html: data.name}}></h3>
                </Reveal>
                <Reveal delay="2">
                    <p>{data.description}</p>
                    {children}
                </Reveal>
            </Content>
        </Container>

    )
}

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: ${props=>props.invert ? 'row' : 'row-reverse'};
    * {
        color: #fff;
    }
    > div {
        left: auto !important;
        transform: none !important;
    }
    ${device.mobile} {
        flex-direction: column;
    }
`


const Content = styled.div`
    width: 3.84rem;
    h3 {
        margin: 0 0 0.08rem;
        span {
            white-space: nowrap;
        }
    }
    p {
        margin: 0 0 0.24rem;
    }
    ${device.mobile} {
        width: auto;
        padding: 0 0.16rem;
        margin-top: 0.36rem;
        text-align: center;
        p:last-child {
            margin-bottom: 0.8rem;
        }
        button:last-child, a:last-child {
            margin-bottom: 0.8rem;
        }
    }
`
